import TagsDatabase from "#constants/TagsDatabase";
import { ClockIcon } from "@heroicons/react/24/outline";
import { format, parseISO } from "date-fns";
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import CategoryLabel from "./category";

const PostLead = ({ post }) => {
  const imageData = post.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData
  return (
    <>
      <div className="cursor-pointer group grid gap-0 md:gap-10 md:grid-cols-2">
        <div className="relative overflow-hidden transition-all bg-gray-100 rounded-md dark:bg-gray-800 group-hover:scale-105 aspect-video">
          <Link to={`/post${post.fields.slug}`}>
            <GatsbyImage
              image={imageData}
              alt={"Thumbnail"}
              placeholder="blur"
              sizes="80vw"
              layout="fullWidth"
              objectfit="cover"
              className="transition-all"
            />
          </Link>
        </div>

        <div className="flex items-center">
          <div>
            <CategoryLabel categories={post.frontmatter.tags.slice(0, 1)} />
            <h2 className="text-lg md:text-3xl font-semibold leading-snug tracking-tight mt-2    dark:text-white">
              <Link to={`/post${post.fields.slug}`}>
                <span className={`bg-gradient-to-r ${TagsDatabase[post.frontmatter.tags[0]].underlineClass} ${TagsDatabase[post.frontmatter.tags[0]].underlineClassDark} bg-[length:0px_10px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_3px] group-hover:bg-[length:100%_10px]`}>
                  {post.frontmatter.title}
                </span>
              </Link>
            </h2>
            <div>
              {post.frontmatter.description && (
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 line-clamp-3">
                  <Link to={`/post${post.fields.slug}`}>
                    {post.frontmatter.description}
                  </Link>
                </p>
              )}
            </div>
            <div className="flex items-center mt-3 space-x-3 text-gray-500 dark:text-gray-400">
              <time
                className="text-sm"
                dateTime={post?.frontmatter.date}>
                <ClockIcon className="inline-block h-5 w-5 mb-1"></ClockIcon> {format(
                  parseISO(post?.frontmatter.date),
                  "MMMM dd, yyyy"
                )}
              </time>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default PostLead;