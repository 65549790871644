import TagsDatabase from "#constants/TagsDatabase";
import { cx } from "#utils/all";
import { ClockIcon } from "@heroicons/react/24/outline";
import { format, parseISO } from "date-fns";
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import CategoryLabel from "./category";

const PostList = ({ post, aspect }) => {
  const imageData = post.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData
  return (
    <>
      <div className="cursor-pointer group">
        <div
          className={cx(
            "relative overflow-hidden transition-all bg-gray-100 rounded-md dark:bg-gray-800 group-hover:scale-105",
            aspect === 'landscape' ? "aspect-video" : "aspect-square"
          )}>
          <Link to={`/post${post.fields.slug}`}>
            <GatsbyImage
              image={imageData}
              alt={"Thumbnail"}
              objectfit="cover"
              className="transition-all"
            />
          </Link>
        </div>
        <CategoryLabel categories={post.frontmatter.tags.slice(0, 1)} />
        <h2 className="mt-2 text-lg font-semibold tracking-normal text-brand-primary dark:text-white">
          <Link to={`/post${post.fields.slug}`}>
            <span className={`bg-gradient-to-r ${TagsDatabase[post.frontmatter.tags[0]].underlineClass} ${TagsDatabase[post.frontmatter.tags[0]].underlineClassDark} bg-[length:0px_10px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_3px] group-hover:bg-[length:100%_10px]`}>
              {post.frontmatter.title}
            </span>
          </Link>
        </h2>

        <div>
          {post.frontmatter.description && (
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 line-clamp-3">
              <Link to={`/post${post.fields.slug}`}>
                {post.frontmatter.description}
              </Link>
            </p>
          )}
        </div>

        <div className="flex items-center mt-3 space-x-3 text-gray-500 dark:text-gray-400">
          <time
            className="text-sm"
            dateTime={post?.frontmatter.date}>
            <ClockIcon className="inline-block h-4 w-4 mb-1"></ClockIcon> {format(
              parseISO(post?.frontmatter.date),
              "MMMM dd, yyyy"
            )}
          </time>
        </div>
      </div>
    </>
  );
}


export default PostList;