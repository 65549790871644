import PostList from "#components/blog/postlist"
import Container from "#components/global/container"
import { useLocation } from "@gatsbyjs/reach-router"
import { graphql } from "gatsby"
import * as React from "react"
import { useEffect } from "react"

import PostLead from "#components/blog/postlead"
import Layout from "#components/global/layout"
import { incrementCounter } from "#utils/firebase"
import Seo from "../components/global/seo"

const BlogIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes.filter(
    post => post.frontmatter.published !== false
  )

  const location = useLocation()

  useEffect(() => {
    incrementCounter(location, "pages/blog/views")
  }, [])

  return (
    <>
      {posts && (
        <Layout siteTitle={{ siteTitle }}>
          <Seo title="Home" description="Writings of a tinkerer" tags="blog" />
          <Container className="!pt-10 !md:pt-24">
            <div className="grid">
              <PostLead post={posts[0]} aspect="landscape" />
            </div>
            <div className="grid gap-10 mt-10 md:mt-24 lg:gap-10 md:grid-cols-2 xl:grid-cols-3 ">
              {posts.slice(1).map(post => (
                <PostList
                  key={post.fields.slug}
                  post={post}
                  aspect="landscape"
                />
              ))}
            </div>
          </Container>
        </Layout>
      )}
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/blog//" } }
    ) {
      nodes {
        excerpt
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "")
          title
          description
          featuredImage {
            childImageSharp {
              resize {
                src
                aspectRatio
              }
              gatsbyImageData(placeholder: DOMINANT_COLOR, width: 700)
            }
          }
          tags
          featuredImageAlt
          published
        }
      }
    }
  }
`
